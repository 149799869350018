const yidanData = {
  name: "Yidan Gong",
  // name2: "Richard Tea.",
  // name3: "Alexa Johnson",
  role: "User Experience Designer",
  ptext: "Creative Designer & Developer located in London.",
  socialLinks: [
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/yidan-gong-738a80217/",
      className: "social_linkedin",
    },
  ],
  aboutme: "About Me",
  aboutdetails:
    "I'm a passionate user experience designer with an interdisciplinary background in advertising and language teaching. I am interested in the domain of Edtech, promising to craft research-based user-centered experiences to promote a more inclusive learning environment and personalized learning experiences.",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Development",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Marketing",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Support",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "We’re experienced",
      sDetails:
        "There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: gyidangela@gmail.com",
          url: "mailto:gyidangela@gmail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: www.gongyidan.com",
          url: "https://www.gongyidan.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default yidanData;
