import React, { Component } from 'react';

class Reflection extends Component {
    render() {
        return (
            <div className='section' id='reflection'>
                <div className='container'>
                    <h1>Reflection</h1>

                    <h3 className='t_center'>Empathize with the Target Group</h3>
                    <p>Empathy and customer-centricity are crucial when approaching any project. Understanding the unique needs, challenges, and desires of customers is key to designing a product that truly reflects their perspective. It was crucial for me to empathize with them and their unique challenges. This required me to delve deeper into the experiences of this target group and understand their perspective, which was not always easy. However, by taking the time to listen and learn from them, as well as professors, teachers, and parents who interact with them on a daily basis, I was able to identify the specific pain points and challenges hard of hearing children faced when learning music. This project highlighted the importance of prioritizing customer needs above all else, and taught me valuable lessons about the power of empathy in UX design.</p>

                    <h3 className='t_center'>Arduino</h3>
                    <p>Completing my first Arduino project has been a rewarding experience that has given me a newfound appreciation for the intersection of hardware and software design. This experience provided me with a deeper understanding of integrating hardware components into user interfaces, and how physical objects and tangible feedback can create more engaging user experiences.</p>
                    <p>While working with Arduino, I came across various technical difficulties and constraints due to the limitations of the hardware's capabilities. These obstacles required me to adopt a more systematic approach to problem-solving. And this process gave me a deeper understanding of the constraints of hardware design and provided me with important learning opportunities to improve my skills in working with physical computing.</p>
                    <br/>
                </div>
            </div>
        )
    }
}

export default Reflection