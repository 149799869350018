import React, { Component } from 'react';

class Reflection extends Component {
    render() {
        return (
            <div className='section bg_color' id='reflection'>
                <div className='container'>
                    <h1>Reflection</h1>

                    <h3>Takeaways</h3>
                    <h5>Research. Research. Research</h5>
                    <p>Looking back at this project, I realize the significance of conducting thorough research before starting any design work. I believe that conducting user interviews, literature reviews, and other research approaches is crucial to gain a comprehensive understanding of customer needs and find support for future design ideas. My experience has reinforced the importance of prioritizing research in creating successful and user-friendly products.</p>

                    <h3>Challenges</h3>
                    <h5>Changing ideations into design opportunities</h5>
                    <p>After conducting thorough literature reviews and interviews, I gained many valuable insights that were relevant to the project. However, it was a daunting task to transform these insights into actionable ideas that could inform the design of the product. As a result, I spent more time than anticipated in this phase, trying to connect the dots and make sense of the information I had gathered. It was also a struggle to identify how these insights could translate into meaningful design opportunities. However, I eventually overcame this challenge by breaking down the insights into smaller, more manageable pieces of information. I started by grouping related insights together and looking for patterns and themes. From there, I was able to identify design opportunities that aligned with the project's goals and objectives.</p>

                    <h5>Integrating a Certain Pedagogy While Fulfilling the Needs of Childs and Parents in One Single Project</h5>
                    <p>The biggest challenge was to design a solution that could meet the needs of both groups effectively. Children needed to be engaged in fun and interactive activities that could hold their attention and encourage continued involvement. Meanwhile, parents needed a structured approach to learning English, which would help them develop their language skills.</p>
                    <p>To overcome this challenge, I started by researching different pedagogical approaches that would be suitable for both childs and parents and their needs. I also gathered feedback through surveys and questionnaires from parents and childs. By gathering feedback through these methods, I was able to design a product that caters to the needs of both children and parents effectively.</p>
                    <br />
                </div>
            </div>
        )
    }
}

export default Reflection