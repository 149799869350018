const ProjectData = [
  {
    title: "Articulate AI - EduBot",
    category: "uxui",
    img: "edubot.png",
    desc: "A conversational platform for non-native English speakers. 3000+ registered users the first day from 100+ countries. According to our first adopters, over 87% of users would recommend the bot to a friend or family.",
    ref: "/Projects/2022301",
    tag: "Product Design",
  },
  {
    title: "VisualMelody",
    category: "uxui",
    img: "visual_melody.jpg",
    desc: "VisualMelody is a device used as a teaching tool to support music education for hard-of-hearing students in the kindergarten classroom. It features a visual and fun way to stimulate musical exploration in hard of hearing students.",
    ref: "/Projects/2022401",
    tag: "UX/UI",
  },
  {
    title: "Our Story",
    category: "uxui",
    img: "our_story.png",
    desc: "An English Learning Application for ESL (English as a Second Language) learners to enhance integrated English skills by actively engaging in various learning tasks in an interactive and communicative way. ",
    ref: "/Projects/2022102",
    tag: "UX/UI",
  },
  {
    title: "Java, JavaScript, HTML, CSS ",
    category: "uxui",
    img: "programming.jpg",
    desc: "Class projects focus on designing, creating, and maintaining websites and mobile platforms, with an emphasis on problem-solving through computer programming exploration.",
    ref: "/Projects/2019101",
    tag: "Programming",
  },
  {
    title: "Teaching ESL to New U.S. Immigrants in New York",
    category: "teaching",
    img: "clp2.png",
    desc: "Time: Summer 2022 \n Teaching Hours: 60 \n Level: Lower Intermediate I \n Location: Community Language Center at Teachers College, Columbia University",
    ref: "/Projects/2022201",
  },
  {
    title: "Teaching Adults Worldwide on English Integrated Skills (Online)",
    category: "teaching",
    img: "clp.png",
    desc: "Time: Spring 2022 \n Teaching Hours: 60 \n Level: Intermediate II",
    ref: "/Projects/2022101",
  },
  {
    title: "Teaching ESL to Spanish Speakers in New York",
    category: "teaching",
    img: "jeep.png",
    desc: "Time: Fall 2021 \n Teaching Hours: 30 \n Level:  Beginner \n Location: Community Impact at Columbia University",
    ref: "/Projects/2021301",
  },
  {
    title: "Teaching EFL to Kindergarten Students in Chengdu, China",
    category: "teaching",
    img: "efl.jpg",
    desc: "Time: Fall 2020 \n Teaching Hours: 64 \n Level: Kindergarten Alphabet Learning \n Location: Chengdu Aizhi Jacaranda Kindergarten",
    ref: "/Projects/2020401",
  },
];

export default ProjectData;