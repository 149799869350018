import React, { Component } from 'react';

class Reflection extends Component {
    render() {
        return (
            <div className='section bg_color' id='reflection'>
                <div className='container'>
                    <br/>
                    <h1>Reflection</h1>
                    <p>Working on the project taught me how to manage the end-to-end development process, from ideation to launch. I learned how to prioritize tasks, manage timelines, and handle unexpected challenges that arise during the development process.</p>

                    <h3>Team Work</h3>
                    <p>Working with the agile product management approach taught me that teamwork and communication are critical for successful product design. Throughout the project, I collaborated with my team members, from understanding technical limitations to incorporating customer feedback into the design. I learned that clear and concise communication is vital to ensure that everyone is aligned with the project's goals and objectives.</p>

                    <h3>Functionality and Customer Needs</h3>
                    <p> I learned how to translate customers' needs and desires into the functionality of the product. By conducting user research, analyzing user feedback, and collaborating with software engineers, I was able to design a product that met the needs of the users while also being technically feasible.</p>
                    
                    <h3>The Future</h3>
                    <p>We will develop a reinforcement learning-based adaptive policy in our system in the future. We will offer more advanced features, such as a personalized curriculum and a more extensive set of conversational topics for users. We also plan to collaborate with other online learning platforms, such as Udemy, to help them design specific conversational agents targeted at specific teaching materials.</p>
                    <br/>
                </div>
            </div>
        )
    }
}

export default Reflection